import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/Header/MedcardHeader';
import Drawer from '../../components/Drawer';
import { PatientInfoLayout, Row, Title, LinkToPayments } from './style';
import FirstRow from './FirstRow';
import SecondRow from './SecondRow';
import ExtendedInfo from './ExtendedInfo';
import { connect, useDispatch } from 'react-redux';
import AdditionalInfo from './AdditionalInfo';
import Formula from './Formula';
import { Appointments } from './Appointments/Appointments';
import { useGetPermissionsQuery } from '../../services/users';
import { routerConfig } from '../../navigation/routerConfig';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { useGetAdvanceTotalQuery } from '../../services/advance';
import EventsWebSocket from '../../websocket';
import { wsUrl } from '../../utils/request';
import {
  connectMedCard,
  editJournalAction,
  startEditInvoiceAction,
  stopEditInvoiceAction,
} from '../../websocket/actionCreators';
import { WS_ACTION_TYPES } from '../../websocket/actionTypes';
import { JournalFieldType } from './Jornal/journalFieldsConfig';
import { medcardJournalApi } from '../../services/medcardJournal';

function MedicalCardPage({ medCardPageSlice, common }: RootState) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isExpanded, setExpantion] = useState(false);
  const { data: permissions } = useGetPermissionsQuery(common.user.id);

  const [session, setSession] = useState(null);

  const socket = useRef();

  function handleExpand() {
    setExpantion(!isExpanded);
  }

  const { first_name, second_name, last_name, id } = medCardPageSlice.patientDetails;
  const { data: advanceTotal } = useGetAdvanceTotalQuery(id, { skip: !id });

  const handleStartEdit = (invoiceId) => {
    socket.current?.send(startEditInvoiceAction(invoiceId));
  };

  const handleStopEdit = (invoiceId) => {
    socket.current?.send(stopEditInvoiceAction(invoiceId));
  };

  const handleEditJournal = (data) => {
    socket.current?.send(editJournalAction({ ...data, patientId: id }));
  };

  const handleWsMessage = (msg) => {
    if (msg.success) {
      if (id) {
        socket.current?.send(connectMedCard(id));
      }
    }

    if (msg.action === WS_ACTION_TYPES.EDIT_MEDCARD) {
      if (msg.data.patientId === id) {
        switch (msg.data.type) {
          case JournalFieldType.TEXT:
            dispatch(medcardJournalApi.util.invalidateTags(['MedcardJournalTextFields']));
            break;
          case JournalFieldType.DIAGNOSIS_MKB:
            dispatch(medcardJournalApi.util.invalidateTags(['MedcardMKBJournalField']));
            break;
          case JournalFieldType.TREATMENT_PLAN:
            dispatch(medcardJournalApi.util.invalidateTags(['MedcardPlanJournalField']));
            break;
        }
      }
    }

    if (msg.action === WS_ACTION_TYPES.EDIT_INVOICE) {
      setSession(msg.data);
    }
  };

  useEffect(() => {
    if (!id) {
      navigate(routerConfig.PATIENTS.path, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      socket.current = new EventsWebSocket(wsUrl.medcard, handleWsMessage);
    }
    return () => socket.current.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        background: '#F9F9F9',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
      }}
    >
      <Header text={id} />
      <Drawer />
      <PatientInfoLayout>
        <Row>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              marginTop: '30px',
            }}
          >
            <div style={{ width: 800 }}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              >
                <div
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    color: '#253446',
                    lineHeight: '24px',
                    maxWidth: 550,
                  }}
                >
                  {`${[last_name, first_name, second_name ?? ''].filter(Boolean).join(' ')}`}
                </div>
                <div
                  style={{
                    fontSize: 14,
                    color: '#253446',
                    marginLeft: 20,
                    marginRight: 10,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '22px',
                    marginTop: 10,
                  }}
                >
                  Баланс (рубли)
                </div>
                <div
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '18px',
                    color: '#6CD0AD',
                    lineHeight: '22px',
                    marginRight: 406,
                  }}
                >
                  {advanceTotal}
                </div>
              </div>
            </div>
            <Title style={{ lineHeight: '22px', marginTop: 10 }}>Состояние здоровья</Title>
          </div>
        </Row>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
          <div>
            <FirstRow />
            <SecondRow />
          </div>
          <div>
            <AdditionalInfo />
          </div>
        </div>
        {permissions['view_payment'] && (
          <LinkToPayments
            onClick={() => {
              navigate(routerConfig.VISITS_AND_PAYMENTS.path);
            }}
          >
            Визиты и оплаты
          </LinkToPayments>
        )}
        {permissions['view_patient_docs'] && <ExtendedInfo isExpanded={isExpanded} handleExpand={handleExpand} />}
      </PatientInfoLayout>
      {permissions['view_patient_medcard'] && (
        <Formula
          session={session}
          onStartEdit={handleStartEdit}
          onStopEdit={handleStopEdit}
          onEditJournal={handleEditJournal}
        />
      )}
      <Appointments patientId={id} />
    </div>
  );
}

export default connect(
  (state: RootState) => ({
    medCardPageSlice: state.medCardPageSlice,
    common: state.common,
  }),
  {},
)(MedicalCardPage);
