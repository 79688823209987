/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import NewDatePicker from '../../components/NewDatePicker';
import Header from '../../components/Header';
import Drawer from '../../components/Drawer';
import RedactButton from './RedactButton';

import DoctorMultiSelect from './DoctorMultiselect';
import MultiSelect from './MultiSelect';
import ScheduleTable from './ScheduleTable';
import EmptyScheduleTable from './EmptyScheduleTable';

import AppointmentForm from './modals/AppointmentForm';
import VisitCard from './modals/VisitCard';
import CommentModal from './modals/VisitCard/components/CommentModal';
import RedactingModal from './modals/RedactingModal';

import { wsUrl } from '../../utils/request';

import { setSelectedDate, setDoctors, setCabinets, setStatuses, setRedactingDay } from './reducer';

import { statuses } from './global/visitStatus';

import { Filters, FiltersWrap, Page, Navigation, HeaderWrap } from './styles';

import {
  scrollTableByValue,
  getMinMaxTime,
  createTimeAxisForTable,
  handleInitializeTableData,
  handleFilterDoctorsChange,
  handleFilterCabinetsChange,
  handleFilterStatusChange,
  syncTableWithBackend,
  handleSelectedDateChange,
} from './ScheduleTable/functions';
import { ScheduleTableStylesWrapper, ScheduleTableWidthWrapper } from './ScheduleTable/styled-components';
import { ResetFiltersButton, SelectBlocker, SpinnerContainer } from './styled-components';
import { useGetWorkdaysQuery, useGetHolidaysQuery, useGetCabinetsDictionaryQuery } from '../../services/dictionaries';
import { useGetAppointmentsQuery } from '../../services/appointments';

import EventsWebSocket from '../../websocket';

import { startEditAppointmentAction, stopEditAppointmentAction } from '../../websocket/actionCreators';

import TimeAxis from './ScheduleTable/TimeAxis';
import { useGetScheduleByClinicIdQuery, useGetStaffByClinicQuery } from '../../services/staffSchedule';
import { useGetPatientsQuery } from '../../services/patients';

function CalendarPage({ ...props }) {
  const dispatch = useAppDispatch();
  const socket = useRef();
  const tableFirstInit = useRef();

  const [, setWsSessions] = useState([]);
  const [tableInitalized, setTableInitalized] = useState(false);
  const [noDoctorsEvents, setNoDoctorsEvents] = useState(false);

  const selectedZoneData = useAppSelector((state) => state.reworkedSchedule.selectedZoneData);
  const clickedAppointmentId = useAppSelector((state) => state.reworkedSchedule.clickedAppointmentId);
  const cancellingAppointmentData = useAppSelector((state) => state.reworkedSchedule.cancellingAppointmentData);
  const filterDoctors = useAppSelector((state) => state.reworkedSchedule.doctors);
  const filterCabinets = useAppSelector((state) => state.reworkedSchedule.cabinets);
  const filterStatuses = useAppSelector((state) => state.reworkedSchedule.statuses);
  const schedulerAPI = useAppSelector((state) => state.reworkedSchedule.schedulerAPI);
  const currentClinicId = useAppSelector((state) => state.common.user.clinic.id);
  const currentUserId = useAppSelector((state) => state.common.user.id);
  const datesInRange = useAppSelector((state) => state.reworkedSchedule.datesInRange);
  const selectedDate = useAppSelector((state) => state.reworkedSchedule.selectedDate);
  const tableWidth = useAppSelector((state) => state.reworkedSchedule.tableWidth);
  const redactingDay = useAppSelector((state) => state.reworkedSchedule.redactingDay);

  const { data: holidays = [], isLoading: areHolidaysLoading } = useGetHolidaysQuery();
  const { data: workdays = [], isLoading: areWorkdaysLoading } = useGetWorkdaysQuery();
  const { data: cabinets = [], isLoading: cabinetsAreLoading } = useGetCabinetsDictionaryQuery();
  const { data: appointments = [], isLoading: appointmentsAreLoading } = useGetAppointmentsQuery(currentClinicId);
  const { data: sessions = [], isLoading: scheduleIsLoading } = useGetScheduleByClinicIdQuery(currentClinicId);
  const { data: staffers = [], isLoading: staffersAreLoading } = useGetStaffByClinicQuery(currentClinicId);
  const { data: patients = [], isLoading: patientsAreLoading } = useGetPatientsQuery(currentClinicId);

  const minMaxTime = getMinMaxTime(workdays, holidays);

  const showFilterResetButton = !!filterCabinets.length || !!filterStatuses.length || !!filterDoctors.length;

  const relevantCabinets = cabinets.filter((cabinet) => cabinet.is_medical);

  const noCabinets = !Boolean(relevantCabinets.length);

  const areAllWorkdaysAreNonWorking = workdays.every((item) => item.is_workday === false);

  const isLoading =
    cabinetsAreLoading ||
    scheduleIsLoading ||
    staffersAreLoading ||
    appointmentsAreLoading ||
    areWorkdaysLoading ||
    areHolidaysLoading ||
    patientsAreLoading;

  const isSchedulerInactive = noDoctorsEvents || noCabinets || areAllWorkdaysAreNonWorking;

  // Инициализация данных в таблицу. Только один раз, когда с бека всё загрузится и когда в Redux будет ссылка на актуальный экземпляр таблицы
  useEffect(() => {
    if (!tableInitalized && !isLoading && schedulerAPI) {
      const data = {
        filterCabinets,
        filterStatuses,
        filterDoctors,
        cabinets: relevantCabinets,
        staffers,
        patients,
        sessions,
        appointments,
        workdays,
        holidays,
        selectedDate,
        setNoDoctorsEvents,
        dispatch,
        schedulerAPI,
      };

      handleInitializeTableData(data, tableFirstInit.current);

      // Вставка компонента TimeAxis в таблицу
      createTimeAxisForTable(schedulerAPI, isSchedulerInactive, <TimeAxis schedulerAPI={schedulerAPI} />);

      setTableInitalized(true);
      tableFirstInit.current = true;
    }
  }, [isLoading, schedulerAPI, tableInitalized]);

  // Изменения данных, происходящие при изменении докторов в фильтрах
  useEffect(() => {
    if (schedulerAPI) {
      const data = {
        filterCabinets,
        filterDoctors,
        cabinets: relevantCabinets,
        workdays,
        holidays,
        patients,
        selectedDate,
        setNoDoctorsEvents,
        setSelectedDate,
        dispatch,
        schedulerAPI,
      };

      handleFilterDoctorsChange(data);
    }
  }, [filterDoctors]);

  // Изменения данных, происходящие при изменении кабинетов в фильтрах
  useEffect(() => {
    if (schedulerAPI) {
      const data = {
        filterCabinets,
        filterDoctors,
        cabinets: relevantCabinets,
        patients,
        workdays,
        holidays,
        setNoDoctorsEvents,
        dispatch,
        schedulerAPI,
      };

      handleFilterCabinetsChange(data);
    }
  }, [filterCabinets]);

  // Изменения данных, происходящие при изменении статусов в фильтрах
  useEffect(() => {
    if (schedulerAPI) {
      const data = {
        filterStatuses,
        filterDoctors,
        cabinets: relevantCabinets,
        patients,
        staffers,
        appointments,
        schedulerAPI,
      };

      handleFilterStatusChange(data);
    }
  }, [filterStatuses]);

  // Изменения данных, происходящие при изменении выбранной даты
  useEffect(() => {
    if (schedulerAPI) {
      const data = {
        filterCabinets,
        filterDoctors,
        relevantCabinets,
        patients,
        workdays,
        holidays,
        selectedDate,
        cabinets: relevantCabinets,
        dispatch,
        schedulerAPI,
      };

      handleSelectedDateChange(data);
    }
  }, [selectedDate]);

  useEffect(() => {
    setTableInitalized(false);
  }, [workdays, holidays]);

  // Хендлер изменения дат для выборщика дат
  const onDateChange = (newValue) => {
    // Сетаем новую дату и перематываем таблицу
    if (Boolean(newValue)) {
      dispatch(setSelectedDate(newValue));
      // Если даты нет - обнуляем дату и никуда не мотаем
    } else {
      dispatch(setSelectedDate(null));
    }
  };

  // Хендлер сброса фильтров
  const onFilterReset = () => {
    filterDoctors.length && dispatch(setDoctors([]));
    filterCabinets.length && dispatch(setCabinets([]));
    filterStatuses.length && dispatch(setStatuses([]));
  };

  // Хендлер изменения данных по WS. Отрабатывает, когда появляются или исчезают рабочие сессии (действия с визитами)
  const handleWsMessage = (msg) => {
    setTimeout(() => syncTableWithBackend(msg, currentUserId, currentClinicId, setWsSessions, schedulerAPI), 500);
  };

  //Обработчик начала сессии. Шлём сигнал, что начинает происходить определённое действие с каким-то визитом
  const handleStartEdit = useCallback((appointmentId) => {
    if (socket.current?.send) {
      socket.current.send(startEditAppointmentAction(appointmentId));
    }
  }, []);

  //Обработчик конца сессии. Шлём сигнал, что  определённое действие с каким-то визитом закончилось
  const handleStopEdit = useCallback((appointmentId) => {
    if (socket?.current?.send) {
      socket.current.send(stopEditAppointmentAction(appointmentId));
    }
    // Уборка табличек-блокеров "Тут работает какой-то врач, одновременная работа невозможна"
    setTimeout(() => {
      const blockerTooltips = document.querySelectorAll('.blockerTooltip');
      blockerTooltips.forEach((blockerTooltip) => blockerTooltip.remove());
    });
  }, []);

  //Инициализация сокета
  useEffect(() => {
    if (schedulerAPI && !socket.current) {
      socket.current = new EventsWebSocket(wsUrl.appointments, handleWsMessage);
      return () => socket.current.disconnect();
    }
  }, [schedulerAPI]);

  return (
    <SelectBlocker onContextMenu={(e) => e.preventDefault()}>
      <Page>
        <Navigation>
          <HeaderWrap>
            <Header modalView={props.modalView} />
            <Filters modalView={props.modalView}>
              <FiltersWrap>
                {!isSchedulerInactive && (
                  <NewDatePicker
                    selectedDate={selectedDate}
                    onChange={(newValue, scrollValue) => onDateChange(newValue, scrollValue)}
                    style={{
                      marginRight: selectedDate ? '16px' : 'auto',
                    }}
                  />
                )}

                {!isSchedulerInactive && selectedDate && (
                  <RedactButton onClick={() => dispatch(setRedactingDay(selectedDate))} />
                )}

                <div style={{ display: 'flex', marginLeft: 'auto' }}>
                  {showFilterResetButton && (
                    <ResetFiltersButton id="resetFiltersButton" onClick={onFilterReset}>
                      Отменить фильтры
                    </ResetFiltersButton>
                  )}
                  <DoctorMultiSelect initialValue={filterDoctors} />
                  <MultiSelect
                    placeholder="Выбрать кабинет"
                    searchPlaceholder="Поиск по кабинетам"
                    initialValue={filterCabinets}
                    values={relevantCabinets.map((c) => ({
                      id: c.id,
                      name: c.title,
                    }))}
                    width={200}
                    autoHeight={true}
                    search={true}
                    name="cabinet"
                  />
                  <MultiSelect
                    placeholder="Выбрать статус"
                    initialValue={filterStatuses}
                    values={statuses}
                    statuses
                    width={230}
                    autoHeight={true}
                    name="status"
                  />
                </div>
              </FiltersWrap>
            </Filters>
            {!props.modalView && <Drawer />}
          </HeaderWrap>
        </Navigation>

        <ScheduleTableStylesWrapper
          id="TableWrapperToScroll"
          elementWidth={tableWidth}
          screenWidth={window.screen.width}
          datesInRange={datesInRange}
          maxTime={
            datesInRange.length > 1
              ? schedulerAPI?.getOption('slotMaxTime').split(':').slice(0, 2).join(':')
              : minMaxTime.slotMaxTime.split(':').slice(0, 2).join(':')
          }
        >
          <ScheduleTableWidthWrapper id="ScheduleTableWidthWrapper" elementWidth={tableWidth}>
            {/*<ScheduleTable handleStartEdit={handleStartEdit} handleStopEdit={handleStopEdit} />*/}
          </ScheduleTableWidthWrapper>
        </ScheduleTableStylesWrapper>

        {isLoading && (
          <SpinnerContainer>
            <div className="spinner" />
          </SpinnerContainer>
        )}

        {isSchedulerInactive && (
          <EmptyScheduleTable
            noDoctorsEvents={noDoctorsEvents}
            noCabinets={noCabinets}
            areAllWorkdaysAreNonWorking={areAllWorkdaysAreNonWorking}
          />
        )}

        {selectedZoneData && (
          <AppointmentForm
            modalView={props.modalView}
            selectedZoneData={selectedZoneData}
            handleStartEdit={handleStartEdit}
            handleStopEdit={handleStopEdit}
          />
        )}
        {clickedAppointmentId && <VisitCard appointmentId={clickedAppointmentId} handleStopEdit={handleStopEdit} />}
        {cancellingAppointmentData && (
          <CommentModal appointment={cancellingAppointmentData} handleStopEdit={handleStopEdit} />
        )}
        {redactingDay && (
          <RedactingModal
            currentDate={new Date(redactingDay)}
            cabinets={relevantCabinets}
            holidays={holidays}
            workdays={workdays}
          />
        )}
      </Page>
    </SelectBlocker>
  );
}

export default CalendarPage;
